import { BannerSliderHandler } from "../classes/BannerSliderHandler";
//import { QuotationsSliderHandler } from "../classes/QuotationsSliderHandler";
import { ShowMoreProjectsHandlerHome } from "../classes/ShowMoreProjectsHandlerHome";

document.addEventListener("DOMContentLoaded", () => {
    let bannerSliderHandler: BannerSliderHandler = new BannerSliderHandler();
    let showMoreProjectsHandler: ShowMoreProjectsHandlerHome =
        new ShowMoreProjectsHandlerHome();
//    let quotationsSliderHandler: QuotationsSliderHandler =
//        new QuotationsSliderHandler();

    bannerSliderHandler.registerEventListeners();
    showMoreProjectsHandler.registerEventListeners();
//    quotationsSliderHandler.registerEventListeners();
});
