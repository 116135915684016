export class BannerSliderHandler {
    transitionTime: number;
    bannerSlider: HTMLElement;
    bannerSlidesWrapper: HTMLElement;
    bannerSlides: NodeListOf<HTMLElement>;
    bannerSlideDurations: Array<number>;
    touchstartX: number;
    touchstartTime: number;
    currentBannerSlide: number;
    nextBannerSlide: number;
    previousBannerSlide: number;
    bannerSlideDotsWrapper: HTMLElement;
    bannerSlideDots: NodeListOf<HTMLElement>;
    maxSwipeTime: number;
    minSwipeDistance: number;
    currentBannerSlideTranstionTimeout: number;
  
    constructor() {
        this.transitionTime =  500;
        this.bannerSlider = document.querySelector(".banner-slider");
        this.bannerSlidesWrapper = this.bannerSlider.querySelector(".slides");
        this.bannerSlides = this.bannerSlidesWrapper.querySelectorAll(".slide");
        this.bannerSlideDurations = [];
        this.touchstartX = 0;
        this.touchstartTime = 0;
        this.maxSwipeTime = 250;
        this.minSwipeDistance = 100;
        this.currentBannerSlide = 0;
        this.nextBannerSlide = 1;
        this.previousBannerSlide = this.bannerSlides.length - 1;
        if (this.bannerSlides.length > 1) {
            this.bannerSlideDotsWrapper =
                this.bannerSlider.querySelector(".slide-nav");
            this.bannerSlideDots =
                this.bannerSlideDotsWrapper.querySelectorAll(".slide-dot");
        }
        this.bannerSlides.forEach((slide) => {
            this.bannerSlideDurations.push(
                parseInt(slide.getAttribute("data-duration"))
            );
        });
    }

    registerEventListeners() {
        this.bannerSlides.forEach((slide) => {
            slide.addEventListener("touchstart", (evt) => {
                this.touchstartX = evt.changedTouches[0].screenX;
                this.touchstartTime = Date.now();
            });
            slide.addEventListener("touchend", (evt) => {
                if (Date.now() - this.touchstartTime < this.maxSwipeTime) {
                    if (
                        evt.changedTouches[0].screenX - this.touchstartX <
                        -this.minSwipeDistance
                    ) {
                        this.stopAutomaticBannerSlideChange();
                        this.relocateBannerSlide(
                            this.previousBannerSlide,
                            "right"
                        );
                        this.currentBannerSlide = this.nextBannerSlide;
                        this.setPreviousAndNextBannerSlides();
                        this.bannerSlides.item(
                            this.previousBannerSlide
                        ).style.transform = "translateX(-100%)";
                        this.bannerSlides.item(
                            this.currentBannerSlide
                        ).style.transform = "translateX(0)";
                        this.setActiveBannerSlideDot(this.currentBannerSlide);
                        this.startAutomaticBannerSlideChange();
                    } else if (
                        evt.changedTouches[0].screenX - this.touchstartX >
                        this.minSwipeDistance
                    ) {
                        this.stopAutomaticBannerSlideChange();
                        this.currentBannerSlide = this.previousBannerSlide;
                        this.setPreviousAndNextBannerSlides();
                        this.setActiveBannerSlideDot(this.currentBannerSlide);
                        this.relocateBannerSlide(
                            this.previousBannerSlide,
                            "left"
                        );
                        this.bannerSlides.item(
                            this.nextBannerSlide
                        ).style.transform = "translateX(100%)";
                        this.bannerSlides.item(
                            this.currentBannerSlide
                        ).style.transform = "translateX(0)";
                        window.setTimeout(() => {
                            this.bannerSlides.item(
                                this.currentBannerSlide
                            ).style.transition = "";
                        }, 1.5 * this.transitionTime);
                        this.startAutomaticBannerSlideChange();
                    }
                }
                this.touchstartX = 0;
                this.touchstartTime = 0;
            });
        });
        if (this.bannerSlides.length > 1) {
            this.bannerSlideDots.forEach((dot) => {
                dot.addEventListener("click", () => {
                    this.stopAutomaticBannerSlideChange();
                    this.currentBannerSlide = parseInt(
                        dot.getAttribute("slide-number")
                    );
                    this.setPreviousAndNextBannerSlides();
                    this.setActiveBannerSlideDot(this.currentBannerSlide);
                    this.bannerSlides.forEach((_, index) => {
                        if (index !== this.currentBannerSlide) {
                            this.relocateBannerSlide(index, "right");
                        }
                    });
                    this.bannerSlides.item(
                        this.currentBannerSlide
                    ).style.transition = "none";
                    this.bannerSlides.item(
                        this.currentBannerSlide
                    ).style.transform = "translateX(0)";
                    window.setTimeout(() => {
                        this.bannerSlides.item(
                            this.currentBannerSlide
                        ).style.transition = "";
                    }, 1.5 * this.transitionTime);
                    this.startAutomaticBannerSlideChange();
                });
            });
            this.startAutomaticBannerSlideChange();
        }
    }

    startAutomaticBannerSlideChange() {
        this.currentBannerSlideTranstionTimeout = window.setTimeout(() => {
            this.relocateBannerSlide(this.previousBannerSlide, "right");
            this.currentBannerSlide = this.nextBannerSlide;
            this.setPreviousAndNextBannerSlides();
            this.bannerSlides.item(this.previousBannerSlide).style.transform =
                "translateX(-100%)";
            this.bannerSlides.item(this.currentBannerSlide).style.transform =
                "translateX(0)";
            this.setActiveBannerSlideDot(this.currentBannerSlide);
            this.startAutomaticBannerSlideChange();
        }, this.bannerSlideDurations[this.currentBannerSlide] * 1000);
    }

    stopAutomaticBannerSlideChange() {
        window.clearTimeout(this.currentBannerSlideTranstionTimeout);
    }

    setPreviousAndNextBannerSlides() {
        this.previousBannerSlide = this.currentBannerSlide - 1;
        if (this.previousBannerSlide < 0) {
            this.previousBannerSlide = this.bannerSlides.length - 1;
        }
        this.nextBannerSlide = this.currentBannerSlide + 1;
        if (this.nextBannerSlide >= this.bannerSlides.length) {
            this.nextBannerSlide = 0;
        }
    }

    setActiveBannerSlideDot(index: number) {
        this.bannerSlideDots.forEach((dot) => {
            if (index >= this.bannerSlideDots.length) {
                index -= this.bannerSlideDots.length;
            }
            if (parseInt(dot.getAttribute("slide-number")) == index) {
                if (!dot.className.includes("active")) {
                    dot.className += " active";
                }
            } else {
                dot.className = dot.className.replace("active", "").trim();
            }
        });
    }

    relocateBannerSlide(index: number, direction: "left" | "center" | "right") {
        if (direction === "left") {
            this.bannerSlides.item(index).style.display = "none";
            this.bannerSlides.item(index).style.transform = "translateX(-100%)";
            window.setTimeout(() => {
                this.bannerSlides.item(index).style.display = "block";
            }, 1.5 * this.transitionTime);
        } else if (direction === "center") {
            this.bannerSlides.item(index).style.display = "none";
            this.bannerSlides.item(index).style.transform = "translateX(0)";
            window.setTimeout(() => {
                this.bannerSlides.item(index).style.display = "block";
            }, 1.5 * this.transitionTime);
        } else if (direction === "right") {
            this.bannerSlides.item(index).style.display = "none";
            this.bannerSlides.item(index).style.transform = "translateX(100%)";
            window.setTimeout(() => {
                this.bannerSlides.item(index).style.display = "block";
            }, 1.5 * this.transitionTime);
        }
    }
}