export class ShowMoreProjectsHandlerHome {
    numberOfProjectsToAdd: number;
    showMoreProjectsButtons: NodeListOf<HTMLElement>;

    constructor() {
        this.numberOfProjectsToAdd = parseInt(
            document
                .querySelector(".project-categories-wrapper")
                .getAttribute("data-projects-to-add")
        );
        this.showMoreProjectsButtons = document.querySelectorAll(
            ".show-more-projects"
        );
    }

    registerEventListeners() {
        this.showMoreProjectsButtons.forEach((showMoreProjectsButton) => {
            showMoreProjectsButton.addEventListener("click", () => {
                const hiddenProjects =
                    showMoreProjectsButton.parentElement.querySelectorAll(
                        ".project-wrapper:not(.visible)"
                    );
                for (
                    let i = 0;
                    i <
                    Math.min(this.numberOfProjectsToAdd, hiddenProjects.length);
                    i++
                ) {
                    hiddenProjects.item(i).className += " visible";
                }
                if (
                    showMoreProjectsButton.parentElement.querySelectorAll(
                        ".project-wrapper:not(.visible)"
                    ).length == 0
                ) {
                    showMoreProjectsButton.className += " hidden";
                }
            });
        });
    }
}
